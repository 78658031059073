function ShopifyPOSSchema() {
  const schema = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Product',
        name: 'Shopify POS',
        brand: {
          '@type': 'Organization',
          name: 'Shopify',
        },
        image:
          'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/df/a4/72/dfa47206-a505-858f-39db-e09fed445e0d/AppIcon-0-1x_U007epad-85-220.png/460x0w.png',
        description: 'Shopify POS IOS app rating',
        aggregateRating: {
          '@type': 'AggregateRating',
          bestRating: '5',
          ratingCount: '7322',
          ratingValue: '4.5',
        },
      },
    ],
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{__html: JSON.stringify(schema)}}
    />
  );
}

export default ShopifyPOSSchema;
